import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ReviewType } from '@/v2/types'
import 'swiper/css'
import 'swiper/css/pagination'

interface ReviewsSliderProps {
  reviews: ReviewType[]
}

const formatReviewerName = (name: string) => {
  if (!name) return ''
  const nameParts = name.split(' ')
  const initials = `${nameParts[0][0]}. ${nameParts[1][0]}.`
  const position = nameParts.slice(2).join(' ')

  return `${initials} ${position}`
}

const ReviewsSlider: React.FC<ReviewsSliderProps> = props => {
  const { reviews } = props

  return (
    <section className='what-people-say-section sm-mx:py-6'>
      <div className='row-auto'>
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          className={`client-say-carousel`}
          spaceBetween={50}
          slidesPerView={3}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          breakpoints={{
            375: {
              slidesPerView: 1
            },
            768: {
              slidesPerView: 2
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50
            }
          }}
        >
          {reviews?.map(review => {
            return (
              <SwiperSlide key={review.id}>
                <div className='flex items-start flex-col gap-[10px]'>
                  <h4 className='font-[600] leading-[24px]'>{review.title}</h4>
                  <p className='text-left text-paragraph'>{review.description} </p>
                  <div className='flex items-center gap-[5px] font-[700]'>
                    <span className='w-[20px] h-[2px] bg-tertiary-home block rounded-md'></span>
                    <span className='text-tertiary-home'>{formatReviewerName(review.reviewer?.name)}</span>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}
export default ReviewsSlider
