import { cn } from '@/lib/utils'
import ResponsiveImage from '@/v2/components/helpers/responsive-image'
import SectionHead from '@/v2/components/helpers/section-head'
import SectionWrapper from '@/v2/components/wrappers/section-wrapper'
import { fadeIn, staggerContainer } from '@/v2/constants/motion'
import { EvenOddArticleCardType } from '@/v2/types/cards'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import React from 'react'

interface EvenOddSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode
  description?: string
  isInverted?: boolean
  articles: EvenOddArticleCardType[]
}

const EvenOddSection: React.FC<EvenOddSectionProps> = props => {
  const { title, description, isInverted = true, articles, className, ...rest } = props

  return (
    <LazyMotion features={domAnimation}>
      <m.section
        variants={staggerContainer(0.5, 0)}
        initial='hidden'
        whileInView='show'
        viewport={{ amount: 0.25 }}
        className={cn('landing-pages-wr section-spacing', className)}
      >
        <SectionWrapper className='xl:max-w-[1100px]' {...rest}>
          <SectionHead title={title} description={description} />
          <div className='mt-[30px] xl:mt-[48px]' />
          <div className=' grid grid-cols-1 md:grid-cols-2 md:gap-[80px] xl:gap-0 xl:grid-cols-1'>
            {articles.map((article, index) => (
              <m.article
                key={article.id}
                variants={
                  isInverted && index % 2 == 1 ? fadeIn('right', 'spring', 0, 1) : fadeIn('left', 'spring', 0, 1)
                }
                className={cn(
                  'flex items-center gap-[40px] xl:flex-row flex-col ',
                  isInverted && index % 2 == 1 && 'xl:flex-row-reverse'
                )}
              >
                <div className='xl:max-w-[633px] w-full flex items-center justify-center relative rounded-[10px] lg:h-[337px]'>
                  <ResponsiveImage
                    src={article.image.src}
                    alt={article.image.alt}
                    className={'lg:h-[332.3px] lg:w-[569.5px] object-contain w-[313.89px] h-[163.75px]  lg:ml-0'}
                  />
                </div>
                <div
                  className={cn(
                    'flex-shrink flex flex-col mr-auto gap-3 md:text-left [&>h2]:text-tertiary-home [&>h2]:text-[24px] [&>h2]:font-bold [&>h2]:leading-[28px] lg:[&>h2]:leading-[32px]',
                    isInverted && index % 2 == 1 ? 'xl:min-w-[400px] ' : 'xl:min-w-[449px]'
                  )}
                >
                  <h2>{article.title}</h2>

                  {article.points && (
                    <ul className='list-disc pl-5 font-medium leading-[28px]'>
                      {article.points?.map(point => {
                        return (
                          <li key={point} className='text-paragraph text-[16px] font-[400]'>
                            {point}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              </m.article>
            ))}
          </div>
        </SectionWrapper>
      </m.section>
    </LazyMotion>
  )
}
export default EvenOddSection
