import { cn } from '@/lib/utils'
import LinkWithTransition from '@/v2/components/helpers/link-with-transition'
import ResponsiveImage from '@/v2/components/helpers/responsive-image'
import SectionHead from '@/v2/components/helpers/section-head'
import { Button } from '@/v2/components/ui/button'
import SectionWrapper from '@/v2/components/wrappers/section-wrapper'
import { fadeIn, staggerContainer } from '@/v2/constants/motion'
import { CardType } from '@/v2/types/cards'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import React from 'react'

interface WhyDifferentSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode
  description?: string
  cards: CardType[]
  ctaButton: { href: string; text: string }
}

const WhyDifferentSection: React.FC<WhyDifferentSectionProps> = props => {
  const { title, description, cards, ctaButton, className, ...rest } = props

  const mid = cards.length / 2

  return (
    <LazyMotion features={domAnimation}>
      <m.section
        variants={staggerContainer(0.5, 0)}
        initial='hidden'
        whileInView='show'
        viewport={{ amount: 0.25 }}
        className={cn('text-center landing-pages-wr section-spacing bg-secondary-first', className)}
      >
        <SectionWrapper {...rest}>
          <SectionHead title={title} description={description} />

          <div className='grid grid-cols-1 md:grid-cols-2 gap-[50px]'>
            <LazyMotion features={domAnimation}>
              {cards.map((card, index) => {
                return (
                  <m.article
                    key={card.id}
                    className='text-center flex xl-min:text-left items-center lg-mn:p-[25px] p-[20px] [&_h3]:text-heading-home
                [&_h3]:font-bold [&_h3]:text-[20px] xl-min:flex-row flex-col [&_h3]:mb-[12px] [&_h3]:mt-5 gap-5'
                    variants={mid <= index ? fadeIn('right', 'spring', 0, 1) : fadeIn('left', 'spring', 0, 1)}
                  >
                    <div className='w-[330px] h-[200px] rounded-lg'>
                      <ResponsiveImage
                        src={card.image.src}
                        alt={card.image.alt}
                        className='xl-min:w-[330px] h-[180px]'
                      />
                    </div>

                    <div>
                      <h3>{card.title}</h3>
                      <p className='text-paragraph leading-[24px] font-normal tex-[16px]'>{card.description}</p>
                    </div>
                  </m.article>
                )
              })}
            </LazyMotion>
          </div>

          <LinkWithTransition href={ctaButton.href} className='self-center lg-mn:self-start mt-4'>
            <Button
              variant='call_to_action'
              className='transition-all animate-bounce mt-5 md-mn:w-[210px] !text-background'
            >
              {ctaButton.text}
            </Button>
          </LinkWithTransition>
        </SectionWrapper>
      </m.section>
    </LazyMotion>
  )
}

export default WhyDifferentSection
