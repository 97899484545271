import { BOOK_NOW } from '@/router/allUrls'

export const featuresSectionContent = {
  description: 'A new kind of self storage',
  cards: [
    {
      id: 1,
      title: 'Prepare Your Items',
      description:
        'Pack your luggage, boxes, or personal items securely, ready for storage or shipping. You need to use quality packing supplies to ensure your belongings are well-protected during transit and storage, giving you peace of mind.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/prepare_your_items.webp',
        alt: 'Prepare Your Items feature'
      }
    },
    {
      id: 2,
      title: 'We Pickup or You Drop off',
      description:
        'You can choose to have your items picked up at a convenient time from your location, or you can drop them off at our facility, giving you flexible options to suit your schedule and needs.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/we_pickup_or_you_drop_off.webp',
        alt: 'We Pickup or You Drop off feature'
      }
    },
    {
      id: 3,
      title: 'We Store Your Items',
      description:
        'Your items are stored safely in our facility, monitored 24/7 for security. Whether you need short-term or long-term storage, we ensure your belongings are protected and ready whenever you need them.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/we_store_your_items.webp',
        alt: 'We Store Your Items feature'
      }
    },
    {
      id: 4,
      title: 'We Ship to You or You Pickup',
      description:
        "When you're ready to retrieve your items, we can deliver them directly to your door, or you can come to our facility and pick them up at a time that works best for you. ",
      image: {
        src: '/images/v2/pages/main-pages/home-page/we_ship_to_you_or_you_pickup.webp',
        alt: 'We Ship to You or You Pickup feature'
      }
    }
  ]
}

export const WhyDifferentSectionContent = {
  ctaButton: { href: BOOK_NOW, text: 'Get Started' },

  cards: [
    {
      id: 1,
      title: 'Storage with freedom',
      description:
        'Store your luggage, boxes, or personal items with complete flexibility. Choose any location, anytime, and enjoy the freedom to drop off or pick up your belongings at your convenience, with easy access to our storage solutions wherever you are.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/storage_with_freedom.webp',
        alt: 'Storage with freedom feature'
      }
    },
    {
      id: 2,
      title: 'We Ship Anywhere',
      description:
        'Need your luggage or boxes shipped? We’ve got you covered! Our reliable shipping services ensure your belongings reach any destination, locally or globally, safely and on time, providing convenience and peace of mind.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/we_ship_anywhere.webp',
        alt: 'We Ship Anywhere feature'
      }
    }
  ]
}

export const evenOddSectionContent = {
  articles: [
    {
      id: 1,
      title: 'Smart Storage Services',
      points: [
        'Up to 6 Months Free Storage',
        'Shipping To Storage Location',
        'Drop Off and Pickup From Storage Available',
        'No Size limit 1 Small Box To 100 Large Box Storage',
        'Pay by Pieces Not for Empty Space',
        'No Contract or Commitment'
      ],
      image: {
        src: '/images/v2/pages/main-pages/home-page/smart_Storage_Services.webp',
        alt: 'Smart Storage Services'
      }
    },

    {
      id: 2,
      title: 'Shipping Services',
      points: [
        'Save up to 80% on Shipping Cost',
        'International Shipping Services',
        'Domestic Shipping Services',
        'Free Shipping Insurance up to $250',
        'Shipping Services Provide by Our Partners',
        'Pickup or Drop Off To Nearest Shipping Center'
      ],
      image: {
        src: '/images/v2/pages/main-pages/home-page/shipping_services.webp',
        alt: 'Shipping Services'
      }
    }
  ]
}

export const advantageSectionContent = {
  ctaButton: { href: BOOK_NOW, text: 'Get Started' },

  cards: [
    {
      id: 1,
      title: 'Free Storage Space',
      description:
        'Enjoy up to 6 months of free storage, giving you plenty of time to store your items securely without any immediate costs or commitments.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/free_storage_space.webp',
        alt: 'Free Storage Space feature'
      }
    },
    {
      id: 2,
      title: 'No Long-Term Contract',
      description:
        'We don’t lock you into long-term contracts. Store your items for as long as you need, with flexible terms that suit your needs without any hidden fees.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/no_long_term_contract.webp',
        alt: 'No Long-Term Contract feature'
      }
    },
    {
      id: 3,
      title: 'No Limit with Location',
      description:
        'With our extensive network of locations, you can choose a storage space that’s convenient for you—just a click away, no matter where you are.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/no_limit_with_location.webp',
        alt: 'No Limit with Location feature'
      }
    },
    {
      id: 4,
      title: 'Pay Only By Each Item',
      description:
        'Unlike traditional storage, you only pay for the items you store, not for unused or empty space. This makes storage affordable and efficient, tailored exactly to your needs.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/pay_only_by_each_item.webp',
        alt: 'Pay Only By Each Item feature'
      }
    }
  ]
}

export const storageFeaturesSectionContent = {
  cards: [
    {
      id: 1,
      title: 'Moving Box Storage',
      description:
        "### with ease. Whether you're in between homes or just need extra space, we securely store your boxes until you're ready to retrieve them.",
      image: {
        src: '/images/v2/pages/main-pages/home-page/moving_box_storage.webp',
        alt: 'Moving Box Storage feature'
      },
      links: [{ text: 'Store your packed moving boxes', href: '/moving-storage-solution' }]
    },
    {
      id: 2,
      title: 'Luggage Storage',
      description:
        'Traveling or short on space? ### with us for as long as you need. Our secure facilities keep your belongings safe, ready for pickup when you need them.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/luggage_storage.webp',
        alt: 'Luggage Storage feature'
      },
      links: [{ text: 'Store your luggage', href: '/luggage-box-shipping-services' }]
    },
    {
      id: 3,
      title: 'Sport Gear Storage',
      description:
        'No more clutter! Safely ###, and other sports gear in our spacious storage units, freeing up space at home while keeping your equipment in top condition.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/sport_gear_storage.webp',
        alt: 'Sport Gear Storage feature'
      },
      links: [{ text: 'store your golf clubs, skis, snowboards ', href: '/moving-storage-solution' }]
    },
    {
      id: 4,
      title: 'Office Document Storage',
      description:
        '### important office documents and files. Our secure storage solutions help you manage and protect your paperwork, providing easy access when needed without taking up valuable office space.',
      image: {
        src: '/images/v2/pages/main-pages/home-page/office_document_storage.webp',
        alt: 'Office Document Storage feature'
      },
      links: [{ text: 'Safely store and archive', href: '/moving-storage-solution' }]
    }
  ]
}

export const reviewsContent = [
  {
    id: 1,
    title: 'Golf Club Storage',
    description:
      'Finding a safe place to store my golf clubs was a priority, and Ship and Storage delivered! The facility is secure, and the pickup/drop-off process couldn’t be simpler. I’ll definitely be using them every season!',
    reviewer: { name: 'David P.', id: 11 }
  },
  {
    id: 2,
    title: 'Office Document Storage and Archive',
    description:
      'Our office was running out of space with all the paperwork piling up. We decided to give Ship and Storage a try for document storage and archiving, and it’s been a fantastic decision. The documents are securely stored, and we can easily access them when needed. No more clutter in the office, and the peace of mind knowing our files are safe is invaluable.',
    reviewer: { name: 'Mark R. COO', id: 12 }
  },
  {
    id: 3,
    title: 'Long Term Storage',
    description:
      'I stored my personal items for over a year. The service was flexible, no long-term contract, and my belongings were safe the entire time. Highly recommend!',
    reviewer: { name: 'Laura M.', id: 13 }
  },
  {
    id: 4,
    title: 'Free Storage',
    description:
      'I took advantage of their free storage offer for 6 months, and it really helped me out during my relocation. I didn’t have to worry about storage costs while I was figuring out my next move. The team was professional and flexible, making the entire process smooth from start to finish. I’ll definitely be using their services again in the future!',
    reviewer: { name: 'Ryan S.', id: 13 }
  },
  {
    id: 5,
    title: 'Shipping Services',
    description:
      'I needed to ship some personal items overseas, and Ship and Storage made it so easy. Their shipping service was fast and affordable, and everything arrived safely. I’ll definitely use them again for future shipments.',
    reviewer: { name: 'Samantha H.', id: 13 }
  },
  {
    id: 6,
    title: 'Pay By Item',
    description:
      'Their pricing is super fair—you only pay for what you store! I wasn’t paying for empty space like with other companies. It saved me so much money, and the service was outstanding!',
    reviewer: { name: 'Isabella K.', id: 13 }
  },
  {
    id: 7,
    title: 'No Long-Term Contract',
    description:
      'I loved the freedom of not being tied into a long-term contract. I only needed storage for a few months while I was in transition, and Ship and Storage made the process so simple and stress-free!',
    reviewer: { name: 'Lucas A.', id: 13 }
  }
]
