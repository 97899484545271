import { cn } from '@/lib/utils'
import FourColGrid from '@/v2/components/helpers/four-col-grid'
import LinkWithTransition from '@/v2/components/helpers/link-with-transition'
import SectionHead from '@/v2/components/helpers/section-head'
import { Button } from '@/v2/components/ui/button'
import SectionWrapper from '@/v2/components/wrappers/section-wrapper'
import { staggerContainer } from '@/v2/constants/motion'
import { CardType } from '@/v2/types/cards'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import React from 'react'

interface AdvantageSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode
  description?: string
  cards: CardType[]
  ctaButton: { href: string; text: string }
}

const AdvantageSection: React.FC<AdvantageSectionProps> = props => {
  const { title, description, cards, ctaButton, className, ...rest } = props

  return (
    <LazyMotion features={domAnimation}>
      <m.section
        variants={staggerContainer(0.5, 0)}
        initial='hidden'
        whileInView='show'
        viewport={{ amount: 0.25 }}
        className={cn('text-center landing-pages-wr section-spacing bg-secondary-first', className)}
      >
        <SectionWrapper {...rest}>
          <SectionHead title={title} description={description} />
          <FourColGrid cards={cards} />
          <LinkWithTransition href={ctaButton.href} className='self-center lg-mn:self-start'>
            <Button
              variant='call_to_action'
              className='transition-all animate-bounce mt-8 md-mn:w-[210px] !text-background'
            >
              {ctaButton.text}
            </Button>
          </LinkWithTransition>
        </SectionWrapper>
      </m.section>
    </LazyMotion>
  )
}

export default AdvantageSection
