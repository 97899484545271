import { cn } from '@/lib/utils'
import SectionHead from '@/v2/components/helpers/section-head'
import SectionWrapper from '@/v2/components/wrappers/section-wrapper'
import { staggerContainer } from '@/v2/constants/motion'

import { ReviewType } from '@/v2/types'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import React from 'react'
import ReviewsSlider from './reviews-slider'

interface ReviewsSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode
  description?: string
  reviews: ReviewType[]
}

const ReviewsSection: React.FC<ReviewsSectionProps> = props => {
  const { title, description, reviews, className, ...rest } = props

  return (
    <LazyMotion features={domAnimation}>
      <m.section
        variants={staggerContainer(0.5, 0)}
        initial='hidden'
        whileInView='show'
        viewport={{ amount: 0.25 }}
        className={cn('text-center landing-pages-wr section-spacing bg-secondary-first', className)}
      >
        <SectionWrapper {...rest}>
          <SectionHead title={title} description={description} />
          <div className='mt-[30px] lg:mt-[48px]' />
          <ReviewsSlider reviews={reviews} />
        </SectionWrapper>
      </m.section>
    </LazyMotion>
  )
}

export default ReviewsSection
