import { Separator } from '@/v2/components/ui/separator'
import { fadeIn } from '@/v2/constants/motion'
import { LazyMotion, domAnimation, m } from 'framer-motion'

interface SectionHeadProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode
  description?: string
}

const SectionHead: React.FC<SectionHeadProps> = props => {
  const { title, description } = props

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        variants={fadeIn('down', 'tween', 0, 0.8)}
        className='[&>h2]:text-heading-home text-center [&_span]:text-tertiary-home  font-bold text-[25px] md-mn:text-[36px] leading-[30px] md-mn:leading-[56px] w-fit mx-auto '
      >
        {title}

        <Separator className='h-[2px] w-[50%] bg-tertiary-home mx-auto mt-[10px] md-mn:mt-[7px] mb-[12px] rounded-lg' />
        {description && (
          <p className='text-paragraph font-[600] text-[18px] md-mn:text-[20px] leading-[28px]'>{description}</p>
        )}
      </m.div>
    </LazyMotion>
  )
}

export default SectionHead
