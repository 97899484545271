import { cn } from '@/lib/utils'
import FourColGrid from '@/v2/components/helpers/four-col-grid'
import SectionHead from '@/v2/components/helpers/section-head'
import SectionWrapper from '@/v2/components/wrappers/section-wrapper'
import { staggerContainer } from '@/v2/constants/motion'
import { CardType } from '@/v2/types/cards'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import React from 'react'

interface FeaturesSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | React.ReactNode
  description?: string
  cards: CardType[]
}

const Features: React.FC<FeaturesSectionProps> = props => {
  const { title, description, cards, className, ...rest } = props

  return (
    <LazyMotion features={domAnimation}>
      <m.section
        variants={staggerContainer(0.5, 0)}
        initial='hidden'
        whileInView='show'
        viewport={{ amount: 0.25 }}
        className={cn('text-center landing-pages-wr section-spacing', className)}
      >
        <SectionWrapper {...rest}>
          <SectionHead title={title} description={description} />
          <FourColGrid cards={cards} isBiggerImage={true} />
        </SectionWrapper>
      </m.section>
    </LazyMotion>
  )
}

export default Features
